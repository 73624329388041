import { IBreadcrumb } from "./interfaces";

export function getSampleBreadcrumbs(sampleId: string) {
  const dashboardCrumb: IBreadcrumb = {
    text: "Dashboard",
    to: {
      name: "dashboard",
      params: { companyType: "a", id: "all" },
    },
    exact: true,
  };

  const sampleCrumb: IBreadcrumb = {
    text: "Sample " + sampleId,
    to: {
      name: "sample-landing-page",
      params: { sampleId: sampleId },
    },
    exact: true,
  };

  return [dashboardCrumb, sampleCrumb];
}

export function getResultBreadcrumbs(
  sampleId: string,
  testId: string,
  routeId: string,
) {
  const testCrumb: IBreadcrumb = {
    text: testId,
    to: {
      name: "result",
      params: { id: routeId },
    },
    exact: true,
  };

  return getSampleBreadcrumbs(sampleId).concat(testCrumb);
}
