






























import { Component, Vue, Prop } from "vue-property-decorator";
import { readShowNavDrawer, readHasAdminAccess } from "@/store/main/getters";
import { readShowAdminView } from "@/store/admin/getters";
import { commitSetShowNavDrawer } from "@/store/main/mutations";
import { commitSetShowAdminView } from "@/store/admin/mutations";
import { dispatchUserLogOut } from "@/store/main/actions";

@Component
export default class NavDrawer extends Vue {
  @Prop({ type: Boolean, default: false })
  showAdminToggle!: boolean;

  get navDrawerModel() {
    if (this.$vuetify.breakpoint.mdAndDown) {
      return readShowNavDrawer(this.$store);
    } else {
      return true;
    }
  }

  set navDrawerModel(value: boolean) {
    commitSetShowNavDrawer(this.$store, value);
  }

  get isAdmin() {
    return readHasAdminAccess(this.$store);
  }

  get showAdminView() {
    return readShowAdminView(this.$store);
  }

  set showAdminView(val: boolean) {
    commitSetShowAdminView(this.$store, val);
  }

  public async logOut() {
    await dispatchUserLogOut(this.$store);
  }
}
