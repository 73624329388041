function applyTimeZone(date: Date, utc_offset: string) {
  const diff =
    new Date("1970-01-01T00:00:00+00:00").getTime() -
    new Date(`1970-01-01T00:00:00${utc_offset}`).getTime();
  return new Date(
    date.getTime() + diff + new Date(date.getTime()).getTimezoneOffset() * 60000,
  );
}

export function formatDate(dateString: string, utc_offset: string | null) {
  const date = utc_offset
    ? applyTimeZone(new Date(dateString), utc_offset)
    : new Date(dateString);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}
