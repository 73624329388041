
































import { Component, Vue, Prop } from "vue-property-decorator";
import { commitSetShowNavDrawer } from "@/store/main/mutations";
import { readHasAdminAccess } from "@/store/main/getters";
import { readShowAdminView } from "@/store/admin/getters";
import { IBreadcrumb } from "@/interfaces";

@Component
export default class NavBar extends Vue {
  @Prop({ type: Boolean, default: false })
  showBack!: boolean;

  @Prop({ type: Boolean, default: false })
  showImages!: boolean;

  @Prop({ type: Boolean, default: false })
  showPreviousResultsBanner!: boolean;

  @Prop()
  breadcrumbs!: IBreadcrumb[] | null;

  onBackClick() {
    this.$router.back();
  }

  onMenuClick() {
    commitSetShowNavDrawer(this.$store, true);
  }

  get showImagesButton() {
    return this.showImages && this.isAdmin && this.showAdminView;
  }

  get isAdmin() {
    return readHasAdminAccess(this.$store);
  }

  get showAdminView() {
    return readShowAdminView(this.$store);
  }
}
